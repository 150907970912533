/* global shared styling */

.title {
    margin-bottom: 1em;
}
 
 h1 {
    font-size: 5em;
    margin-bottom: 0em;
    color: #333;
 }
   

.blue { 
    background-color: #017BEA;
}
 
h2 {
    font-size: 1.5em; 
    font-weight: normal;
}
   
input {
    font-size: 1.5em;
    font-weight: bold;
    color: #c0c0c0;
    padding: 20px;
    margin: 5px;
    width: 400px; 
    border: 2px solid #c0c0c0; /* 25% grey */
    border-radius: 30px;
}
 
button {
    font-size: 1.5em;
    font-weight: bold;
    padding: 20px;
    margin: 5px;
    min-width: 200px;
    border-radius: 30px;
    background-color: #017BEA;
    color: white;
    border: none;

    opacity: 0;
    animation: fadeIn 0.7s forwards;
}

.button--pressed {
  background-color: #3495EE;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}