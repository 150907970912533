.landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 1000px;
    margin: 15vh auto;
}

.name {
    font-family: 'EB Garamond';
    font-size: 80pt;
    font-weight: 500;
}