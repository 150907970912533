.recording-indicator {
    margin: 100px auto;    
    background-color: #017BEA; 
    border-radius: 50%; 
    width: 0;
    height: 0;
    animation: growSize 1s forwards;
    filter: blur(30px); 
}

@keyframes growSize {
    to {
        width: 100px;
        height: 100px;
    }
}

.recording-indicator__container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

@keyframes pulse {
    0%, 100% {
        transform: scale(0.9);
    }
    50% {
        transform: scale(3.0); 
    }
}

.recording-indicator--on {
    width: 100px;
    height: 100px;
    animation: pulse 5s infinite ease-in-out;
}