.display-result-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 1000px;
    width: 100%;
    font-family: 'EB Garamond'
}

.bullet-title {
    font-family: 'Inter';
    font-weight: 500;
}

.doc-title {
    margin-bottom: 20px;
}

.doc-subtitle {
    font-size: 20pt; 
    font-weight: 400; 
    font-style: italic;
    margin-bottom: 40px;
}

.document h1 {
    font-size: 28pt;
    font-weight: 400;
    margin-top: 0;
}

.document h2 {
    font-size: 18pt;
}

.document h3 {
    font-family: 'Inter';
    font-size: 12pt;
    font-weight: 500;
}

.document p {
    font-family: 'Inter';
    font-size: 12pt;
    font-weight: 400;
    margin-bottom: 0.5em;
    line-height: 150%;
}

.document {
    overflow-y: scroll;
    background-color: #F7F5EB;
    width: 100%;
    height: 100vh; 
    box-sizing: border-box;
    text-align: left;
    padding: 100px;
    display: flex; 
    flex-direction: column;
    opacity: 0;
    animation: fadeIn 1s forwards;
}
.content {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12pt;
    line-height: 150%;
}