.recording-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    height: 100vh;
}

.content-section {  
    height: 100%;
    background-color: #F7F5EB;
    flex: 1;
}

.instructions-section {
    height: 100%;
    flex: 1;
}

.instructions-container {
    max-width: 100%;
    height: 100%;
    padding: 100px;
    box-sizing: border-box;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.instructions-prompts {
    max-height: 60%;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.prompt {
    font-family: 'Inter', sans-serif;
    font-weight: 600; /* semibold */

    line-height: 1.5;
    font-size: 16px;
    padding: 5px;
    color: rgba(0, 0, 0, 0.4);

    animation: fadeIn 1s forwards;
}

.prompt--most_recent {
    color: rgba(0, 0, 0, 1);
}

.instructions-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.loading-indicator { /* text loading indicator */ 
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600; /* semibold */
    text-align: center;
    animation: opacity-pulse 5s infinite ease-in-out;
    font-size: 2em;
}

@keyframes opacity-pulse {
    0%, 100% {
        opacity: 1.0;
    }
    50% {
        opacity: 0.2; 
    }
}